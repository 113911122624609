.container {
    height: 100vh;
    width: 100%;
}
.sigma {
    position: relative;
    width: 100%;
    height: 100%;
    background: white;
    overflow: hidden;
}
.controls {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 5px;
    background-color: white;
}
